<template>
    <div class="grid" id="nav-bar">
        <div>
          <a href="/" class="uppercase">
            <!-- <div class="">Emma Schüpfer</div>
            <div>Design + Code</div> -->
          </a>
        </div>
        <!-- <div class="center more">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="32" height="32" preserveAspectRatio="xMidYMid meet" style="width: 100%; height: 100%; transform: translate3d(0px, 0px, 0px);">
            <g>
              <g transform="matrix(0.952360987663269,0,0,0.952360987663269,1.1909751892089844,1.1909751892089844)" opacity="1" style="display: block;">
                <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                  <path stroke-linecap="butt" stroke-linejoin="miter" fill-opacity="0" stroke-miterlimit="10" stroke="rgb(0,0,0)" stroke-opacity="1" stroke-width="1" d=" M16,0 C16,0 16,32 16,32"></path>
                </g>
                <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                  <path stroke-linecap="butt" stroke-linejoin="miter" fill-opacity="0" stroke-miterlimit="10" stroke="rgb(0,0,0)" stroke-opacity="1" stroke-width="1" d=" M32,16 C32,16 0,16 0,16"></path>
                </g>
              </g>
            </g>
          </svg>
        </div> -->
        <div>
          <div class="uppercase align-right" id="place-time">
            <div id="place">
              <transition appear @before-enter="beforeEnter" @enter="enter">
                <div class="inner">Würzburg, DE</div>
              </transition>
            </div>
            <div id="time">
              <transition appear @before-enter="beforeEnter" @enter="enterSecond">
              <div class="inner">{{ time }}</div>
            </transition>
            </div>
          </div>
        </div>
      </div>
  </template>
  
 <script>
  import gsap from "gsap"

  export default {
    name: 'NavBar',

    data() {
    return {
      interval: null,
      time: null
    }
  },
  methods: {
    beforeEnter(el) {
      gsap.set(el, {
         yPercent: 100,
        });
        // gsap.set("#nav-bar", {
        //  opacity: 0,
        // })
      },
      enter(el){
        var tlStart = gsap.timeline({repeat: 0, repeatDelay: 1});
        tlStart.to(el, {duration: 1.5});
        tlStart.to(el, {
          yPercent: 0,
          duration: 0.5
        })
      },
      enterSecond(el){
        var tlStart = gsap.timeline({repeat: 0, repeatDelay: 1});
        tlStart.to(el, {duration: 1.8});
        tlStart.to(el, {
          yPercent: 0,
          duration: 0.5
        })
      }
  },
  beforeDestroy() {
    // prevent memory leak
    clearInterval(this.interval)
  },
  created() {
    // update the time every second
    this.interval = setInterval(() => {
      // Concise way to format time according to system locale.
      // In my case this returns "3:48:00 am"
      this.time = Intl.DateTimeFormat(navigator.language, {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      }).format()
    }, 1000)
  }
  }

  </script>
  
  <style scoped lang="scss">
  .grid {
  display: grid;
    grid-row-gap: 1rem;
    grid-column-gap: 1rem;
    grid-template-columns: 1fr 1fr ;
    -ms-grid-rows: auto;
    grid-template-rows: auto;

    .center{
      align-self: center;
      justify-self: center;
    }
}
    
    #nav-bar{
      z-index: 9999;
        position: fixed;
        width: 100%;
        color: rgb(126, 126, 126);

        .more{
          visibility: hidden;
          &:hover{
          cursor: pointer;
        }
        }

        #place-time{
          color: rgb(229, 229, 229);
          font-size: 14px
        }

        #time{
          font-weight: 400;
        }

        #place, #time{
          overflow: hidden;

          .inner{
            position: relative;
          }
        }
    }
  </style>