<template>
    <section id="home">
      <transition appear @before-enter="beforeEnter" @enter="enter">
        <BusinessCard/>
      </transition>
      <div>
        <!-- <p>
          Hey there, I'm Emma – a HCI designer from Germany. I hop from Software Engineering to Art and traditional Interface Design. Please feel free to reach out.
        </p> -->
      <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
      <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
        <!-- <h1><a href="mailto:email@example.com"><span>hello@</span><span>emmaschuepfer.de</span></a></h1> -->
      </div>
    </section>
</template>

<script>
import gsap from 'gsap'
import BusinessCard from '@/components/BusinessCard.vue'

export default {
  name: 'Home',
  components: {
    BusinessCard
  },
  data: function() {
    return {
      timer: 0,
      front: true,
      canFlip: true,
    };
  },
  methods: {
    flipCard(){
      var card = this;
      if (card.canFlip == true) {
        card.canFlip = false;
        if (card.front == true){
          var tl = gsap.timeline({repeat: 0, repeatDelay: 1,
            onStart: function(){
              card.canFlip = false;
            },
            onComplete: function(){
              card.front = false;
              card.canFlip = true;
            }
          });

          // 
          // tl.fromTo(".card-back-overlay", {rotationY: 180}, {rotationY: 360, duration: 1});
          tl.fromTo(".card-back", {rotationY: 180}, {rotationY: 360, duration: 1});
          tl.fromTo(".card-front", {rotationY: 0}, {rotationY: 180, duration: 1}, "<");
          tl.to(".card-front", {duration: 1.5}, "<");
        } 
        else if (card.front == false){

          var tl2 = gsap.timeline({repeat: 0, repeatDelay: 1,
            onStart: function(){
              card.canFlip = false;
            },
            onComplete: function(){
              card.front = true;
              card.canFlip = true;
            }
          });

          tl2.set(".card-back-overlay", {"z-index": 2});
          tl2.fromTo(".card-front", {rotationY: 180}, {rotationY: 360, duration: 1});
          // tl2.fromTo(".card-back-overlay", {rotationY: 0}, {rotationY: 180, duration: 1}, "<");
          tl2.fromTo(".card-back", {rotationY: 0}, {rotationY: 180, duration: 1}, "<");
          tl2.to(".card-front", {duration: 1.5}, "<");
        } 
      }
  
      // if(this.timer) {
      //   window.clearTimeout(this.timer);
      // }
      // this.timer = window.setTimeout(function() {
      //   thisVue.front = !thisVue.front;
      //   window.clearTimeout(this.timer);
      // }, 50);
    },
    beforeEnter(el) {
      gsap.set(el, {
         yPercent: 250,
         rotationY: 10,
         rotationX: 70,
         rotationZ: 20,
        });
        // gsap.set("#nav-bar", {
        //  opacity: 0,
        // })
      },
      enter(el){
        var tlStart = gsap.timeline({repeat: 0, repeatDelay: 1});
        tlStart.to(el, {duration: 0.5});
        tlStart.to(el, {
          yPercent: 0,
          rotationY: 0,
          rotationX: 0,
          rotationZ: 0,
          duration: 1
        })
      }
  },
  mounted() {
    document.addEventListener("wheel", this.flipCard);   
    document.addEventListener("touchstart", this.flipCard);  
    document.getElementById("card").addEventListener("mousedown", this.flipCard);   
  }
}
</script>

<style scoped lang="scss">
  #home{
    background: radial-gradient(#1f1f1f, black);
    height: 100vh;
    padding: 2rem 2rem; 
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
  }

  div {
    height: 100%;
    display: flex;
    align-items: flex-end;
  }

  h1{
    font-weight: 500;
    font-size: 8vw;
    line-height: 1;
    letter-spacing: -2.5px;
  }


</style>
