<template>
    <div id="custom-cursor" :class="[ 'g-cursor', { 'g-cursor__hover': hover }, {'g-cursor__hide': hideCursor} ]">
      <!-- <transition appear @before-enter="beforeEnter" @enter="enter">
        <div></div>
      </transition> -->
      <div class="g-cursor__point" ref="point"></div>
    </div>
  </template>
  
 <script>
  import gsap from 'gsap'
  export default {
    name: 'CustomCursor',
    data: function() {
        return {
            xChild: 0,
            yChild: 0,
            hover: false,
            hideCursor: true
        };
    },
  computed: {
  },
  methods: {
    moveCursor(e) {
      gsap.to("#custom-cursor", {
        x: (e.clientX - 8),
        y: (e.clientY - 8),
      })
      // this.xChild = e.clientX;
      // this.yChild = e.clientY;
      // setTimeout(() => {
      //   this.xParent = e.clientX;
      //   this.yParent = e.clientY;
      // }, 100);
    },
    beforeEnter(el) {
      gsap.set(el, {
         
        })
      },
      enter(el){
        gsap.to(el, {
        })
      }
  },
  mounted() {
    document.addEventListener("mousemove", this.moveCursor);
    document.addEventListener('mouseleave', () => {
      this.hideCursor = true;
    });
    document.addEventListener('mouseenter', () => {
      this.hideCursor = false;
    });
    
  }
  }

  </script>
  
  <style lang="scss"> 
  .g-cursor {
    mix-blend-mode: difference;
    position: absolute;
    z-index: 9999;
      &__hide {
          position: absolute;
          opacity: 0;
          width: 00px;
          height: 00px;
          transition: width .6s ease,
          height .2s ease,
          opacity .2s ease;
      }

      &__point {
          top: 0;
          left: 0;
          position: absolute;
          width: 1rem;
          height: 1rem;
          pointer-events: none;
          user-select: none;
          border-radius: 100%;
          background: #ffffff;
          z-index: 55555555;
          backface-visibility: hidden;
          will-change: transform;
          mix-blend-mode: difference;
      }

      &__hover {
      .g-cursor__circle {
          opacity: 0;
          width: 60px;
          height: 60px;
          transition: width .2s ease,
          height .2s ease,
          opacity .2s ease;
      }
      }
  }



  </style>