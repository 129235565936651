<template>
    <div class="card" id="card">
        <div class="card-front">
            <h2 class="text"><span>Emma Schüpfer</span></h2>
            <!-- <div><span>Design</span><span>+</span><span>Code</span></div> -->
        </div>
        <div class="card-back">
            <div>
                <h2 class="text-back"><span>Emma Schüpfer</span></h2>
            </div>
            <div class="card-back-overlay">
                <div class="card-content">
                    <div class="column">
                        <span class="name">Emma Schüpfer</span>
                        <span class="description">Design + Code</span>
                    </div>
                    <div class="column">
                        <span class="mail align-right"><a href="mailto:hello@emmaschuepfer.de">hello@emmaschuepfer.de</a></span>
                        <span class="phone align-right"><a href="tel:+4916095956075">+49 160 95956075</a></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'BusinessCard',
    props: {
      msg: String
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="scss">
  

*, *:before, *:after {
	box-sizing: border-box;
	outline: none;
}

.card {
	position: relative;
	width: 34rem;
	height: 22rem;
	perspective: 150rem;
	&-front,
	&-back, 
  &-back-overlay{
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: auto;
		border-radius: 5px;
		box-shadow: 0 1.5rem 4rem rgba(black, 0.4);
		transition: 0.9s cubic-bezier(.25,.8,.25,1);
		backface-visibility: hidden;
		overflow: hidden;
	}
	&-front {
		width: 100%;
		height: 100%;
    background-color:#bbb;
    backface-visibility: hidden;
    z-index: 1;
    
    h2{
      color:#bbb;
      text-shadow:2px 2px 4px rgba(255,255,255,0.3),-2px 2px 2px rgba(0,0,0,0.2);
    }
	}
	&-back {
    background-color: #bbb;
	transform: rotateY(180deg);
    z-index: 2;
    
        h2{
        color:#bbb;
        text-shadow:2px 2px 4px rgba(255,255,255,0.3),-2px 2px 2px rgba(0,0,0,0.2);
        }
	}
    &-back-overlay{
		z-index: 3;
	}
	
	
// 	&:hover &-front {
// 		transform: rotateY(-180deg);
// 	}
// 	&:hover &-back {
// 		transform: rotateY(0deg);
// 		//z-index: 2 !important;
// 	}
//   &:hover &-back-overlay {
// 		transform: rotateY(0deg);
// 		//z-index: 2 !important;
// 	}
}

h2{
  padding: 0;
  margin: 0;
  font-weight: 400;
  text-transform: uppercase;
  font-size:32px;
  text-align: center;
}

.text-back{
    -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
}

.card-back-overlay {
  width: 100%;
  height: 100%;
  padding: 2rem 2rem;
  font-weight: 400;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  letter-spacing: 0.15px;
  color: #1d1d1d;
  mix-blend-mode: multiply;
  z-index: 5;

  .card-content{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    line-height: 1.5;
  }
}

@media screen and (min-width: 481px) {
    .card-content{
        align-items: flex-end;
    }
}
@media screen and (max-width: 480px) {

    .card {
        width: calc(100vw - 4rem)!important;
        //height: calc((100vw - 4rem) / 5.5 * 8.5);
        height: calc(100vw - 4rem);
        transform: rotateZ(90deg);
    }
    .card-back-overlay{
        padding: 1rem;
    }
    .card-content{
        align-items: stretch;
        flex-direction: column;
    }

    h2 {
        font-size: 28px;
    }
}

.phone:hover, .mail:hover{
    cursor: pointer;
}

.column{
    display: flex;
    flex-direction: column;
}

.name {
    font-weight: 500;
}

.name, .description{
    text-transform: uppercase;
}

  </style>
