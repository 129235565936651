<template>
  <div id="app">
    <NavBar/>
    <CustomCursor/>
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <router-view/>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar.vue'
import CustomCursor from '@/components/CustomCursor.vue'
// import gsap from 'gsap'

export default {
  name: 'Home',
  components: {
    NavBar, 
    CustomCursor
  },
  methods: {
  }
}
</script>

<style lang="scss">

:root {
  --font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  --color-bg: rgb(0, 0, 0);
  --color-text: rgb(0, 0, 0);
  --color-link-active: rgb(40, 40, 40);
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  overscroll-behavior-x: none;
}

html {
  overscroll-behavior-x: none;
}

#app {
  font-family: var(--font-family);
  font-size: 1rem;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-x: none;
  width: 100%;
}

body {
  background: var(--color-bg) ;
  width: 100%;
  overscroll-behavior-x: none;
  // position: absolute;
  // top: 0px;
  // left: 0px;
}

section, #nav-bar {
  padding: 2rem 2rem;
}

p, div, a, span {
  &.uppercase {
  text-transform: uppercase!important;
  }
  & .align-right {
    text-align: right;
  }
  & .bold{
    font-weight: 500;
  }
}

a{
  color: inherit;
  text-decoration: none;
}

@media screen and (max-width: 700px) {
  #app{
    font-size: 5 rem;
  }
}
 


// height: calc(var(--vh, 1vh) * 100);

// #nav {
//   padding: 30px;

//   a {
//     font-weight: bold;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
</style>
